

















































































































import { defineComponent, onMounted } from "vue";
import { RegisterRequestInterface } from "@/models/auth/RegisterRequest.interface";
import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
  sameAs
} from "@vuelidate/validators";
import { isCyrillic, notCyrillic } from "@/validations/person";
import InputMask from "@/components/UI/InputMask.vue";
import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import { confirmUser, register } from "@/api/user";
import { UserTableHeaderInterface } from "@/models/store/UserTableHeader.interface";
import { UserFormInterface } from "@/models/store/User.interface";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";

import { useUserStore } from "@/store/useUserStore";

export default defineComponent({
  components: { InputMask },
  setup() {
    const form = reactive<RegisterRequestInterface>({
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      userId: "",
      packageItem: ""
    });
    const rules = computed(() => {
      return {
        email: {
          required,
          email
        },
        password: {
          required,
          min: minLength(10),
          notCyrillic,
          hasSymbol(password: unknown): boolean {
            const passwordStr = password as string;
            const regExp = new RegExp("[!\"#$%&'()*+,-./:;< =>?@[\\]^_`{|}~]");

            return !helpers.req(passwordStr) || regExp.test(passwordStr);
          },
          hasNumber(password: unknown): boolean {
            const passwordStr = password as string;
            const regExp = new RegExp("[0-9]");

            return !helpers.req(password) || regExp.test(passwordStr);
          },
          hasCapitalLetter(password: unknown): boolean {
            const passwordStr = password as string;
            const regExp = new RegExp("[A-Z]");

            return !helpers.req(password) || regExp.test(passwordStr);
          }
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs(form.password)
        },
        name: {
          required,
          isCyrillic
        },
        userId: {
          required,
          min: minLength(36),
          max: maxLength(36)
        },
        packageItem: {
          required,
          min: minLength(36),
          max: maxLength(36)
        }
      };
    });
    const v$ = useVuelidate(rules, form);
    const usersStore = useUserStore();

    const reqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
    const reqMessage = ref<string>("");
    const usersLoading = ref<boolean>(false);

    const loading = computed<boolean>(() => {
      return reqStatus.value === RequestStatusEnum.Loading;
    });
    const userTableHeaders: UserTableHeaderInterface[] = [
      {
        text: "ФИО",
        value: "title",
        sortable: false
      },
      {
        text: "Email",
        value: "email",
        sortable: false
      },
      {
        text: "Дата создания",
        value: "creationDate",
        sortable: true
      },
      {
        text: "Id пользователя",
        value: "userId",
        sortable: false
      },
      {
        text: "Id пакета интеграции",
        value: "packageItem",
        sortable: false
      },
      {
        text: "Подтверждён",
        value: "isConfirmed",
        sortable: false
      },
      {
        text: "Действия",
        value: "action",
        sortable: false
      }
    ];
    const users = computed(() => {
      return usersStore.users;
    });

    const confirmUserItem = async (user: UserFormInterface) => {
      try {
        user.isLoading = true;

        const res = await confirmUser(user.id);

        user.isLoading = false;
        if (!res.isSuccess) return;

        user.isConfirmed = true;
      } catch (e) {
        user.isLoading = false;
        throw e;
      }
    };

    const loadUsers = async () => {
      usersLoading.value = true;

      await usersStore.getUsers();

      usersLoading.value = false;
    };
    const send = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) return;

      try {
        reqStatus.value = RequestStatusEnum.Loading;

        const res = await register(form);

        if (!res.isSuccess) {
          reqStatus.value = RequestStatusEnum.Error;
          reqMessage.value = res.message;

          return;
        }

        await loadUsers();

        reqStatus.value = RequestStatusEnum.Success;
        reqMessage.value = "Успех";
      } catch (e) {
        reqStatus.value = RequestStatusEnum.Error;
      }
    };

    onMounted(() => {
      loadUsers();
    });

    return {
      form,
      reqStatus,
      reqMessage,
      usersLoading,
      v$,
      userTableHeaders,
      loading,
      users,
      confirmUserItem,
      send,
      loadUsers
    };
  }
});
